import NopimsPageLayout from "../shell/NopimsPageLayout";
import { nopimsLhsNavItemEnum } from "../shell/NopimsLhsNav";
import React from "react";
import ExternalLinkView from "../../common/externalLink/ExternalLinkView";
import EmailLinkView from "../../common/externalLink/EmailLinkView";
import CopyODataLinkView from "./CopyODataLinkView";

export default function Controller(): React.JSX.Element {
    return (
        <NopimsPageLayout title={[""]} lhsNavItem={nopimsLhsNavItemEnum.Home}>
            <div className="container p-0">
                <h1 className="fw-bold">Welcome to the National Offshore Petroleum Information Management System</h1>
                <p>
                    The National Offshore Petroleum Information Management System (NOPIMS) provides access to disclosable, publicly available information concerning offshore petroleum and greenhouse
                    gas storage activities, submitted under the <i>Offshore Petroleum and Greenhouse Gas Storage Act 2006</i> (OPGGS Act), in accordance with the OPGGS Act Resource Management
                    Administration (RMA) Regulations 2011 and predecessors.
                </p>
                <p>The key features of NOPIMS include:</p>
                <ul>
                    <li>The ability to search for well and survey, including reprocessing data using a range of search criteria,</li>
                    <li>Discoverability of activities conducted under the RMA Regulations 2011 upon commencement and prior to information release,</li>
                    <li>Download data for activities released under Regulations and following the expiration of specified confidentiality periods, and</li>
                    <li>An interactive map representing the activities within NOPIMS.</li>
                </ul>
                <p>
                    NOPIMS will continue to be enhanced over time. If you would like to be involved in workshops on the future of NOPIMS please register via{" "}
                    <EmailLinkView emailAddress="nopims.admin@nopta.gov.au" />.
                </p>
                <p>
                    Please visit{" "}
                    <ExternalLinkView href="https://www.nopta.gov.au" className="card-link">
                        www.nopta.gov.au
                    </ExternalLinkView>{" "}
                    to learn more about NOPTA, and to access links to legislation, regulations and other guidance material.
                </p>
                <p>
                    More information on the public release of data can be found at{" "}
                    <ExternalLinkView href="https://www.nopta.gov.au/maps-and-public-data/public-release.html" className="card-link">
                        www.nopta.gov.au/maps-and-public-data/public-release.html
                    </ExternalLinkView>
                    .
                </p>

                <h4>Contact Details</h4>
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-lg-4 col-md-5 col-sm-12 col-xs-12">
                            <span className="fw-bold">NOPIMS Data Release, Compliance and Data Quality</span>
                            <p>
                                Email: <EmailLinkView emailAddress="data@nopta.gov.au" />
                            </p>
                        </div>
                        <div className="col-lg-4 col-md-5 col-sm-12 col-xs-12">
                            <span className="fw-bold">NOPIMS Data Availability (including ‘missing’ data)</span>
                            <p>
                                Email: <EmailLinkView emailAddress="clientservices@ga.gov.au" />
                            </p>
                        </div>
                        <div className="col-lg-4 col-md-5 col-sm-12 col-xs-12">
                            <div className="fw-bold pe-lg-4">NOPIMS System Enquiries, including Accessibility and Technical Support</div>
                            <p>
                                Email: <EmailLinkView emailAddress="nopims.admin@nopta.gov.au" />
                            </p>
                        </div>
                    </div>
                </div>

                <h4>Access Data Feed (OData)</h4>
                <p>
                    The following information is available via Open Data Protocol (OData).
                    <div>To open this data in Microsoft Excel, select Data -&gt; Get Data -&gt; From Other Sources -&gt; From OData Feed and enter the applicable URL from the buttons below.</div>
                </p>
                <CopyODataLinkView title="Wells" oDataPath="/odata/v1/public/nopims/well/PublicNopimsWells" />
                <CopyODataLinkView title="Surveys" oDataPath="/odata/v1/public/nopims/survey/PublicNopimsSurvey" />
                <CopyODataLinkView title="Reprocessings" oDataPath="/odata/v1/public/nopims/reprocessing/PublicNopimsReprocessings" />
                <CopyODataLinkView title="Released Documents" oDataPath="/odata/v1/public/nopims/releasedDocument/PublicNopimsReleasedDocument" />
                <CopyODataLinkView title="Core Library" oDataPath="/odata/v1/public/nopims/coreLibrary/PublicNopimsCoreLibrary" />
            </div>
        </NopimsPageLayout>
    );
}
