// framework
import React, { useEffect, useState } from "react";
// kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import CustomGridCell from "../../../../common/kendoGrid/CustomGridCell";
import KendoComboBox from "../../../../common/kendo/KendoComboBox";
import KendoDropDownList from "../../../../common/kendo/KendoDropDownList";
// api
import * as Client from "../../../../api/Client";
// common
import * as Forms from "../../../../common/forms/BootstrapForms";
import { IValidationModel } from "../../../../common/validation/ValidationModel";
import ValidationMessageView from "../../../../common/validation/ValidationMessageView";
// models
import { ICoreCuttingsRequestViewModel, IRootViewModel, ISampleViewModel } from "../models/models";
// views
import AddSampleController from "../addSample/Controller";

interface IProps {
    vm: IRootViewModel;
    setVm: (vm: IRootViewModel) => void;
    validation: IValidationModel;
    onReset: () => void;
    onSubmit: () => void;
}

export default function CoreCuttingsRequestCreateView(props: IProps): React.JSX.Element {
    const { vm, setVm, validation, onReset, onSubmit } = props;

    const [state, setState] = useState<ICoreCuttingsRequestViewModel>(vm.coreCuttingsRequest);
    const honorifics = vm.referenceData.honorifics;
    const countries = vm.referenceData.countries;
    const countrySubDivisions = vm.referenceData.countrySubDivisions;

    const [showAddSampleView, setShowAddSampleView] = useState(false);

    useEffect(() => {
        setState(vm.coreCuttingsRequest);
    }, [vm.coreCuttingsRequest]);

    function updateViewModel() {
        setVm(vm.refreshCoreCuttingsRequestDetails({ ...state }));
    }

    function updateStateAndViewModel(state: ICoreCuttingsRequestViewModel) {
        setState(state);
        setVm(vm.refreshCoreCuttingsRequestDetails({ ...state }));
    }

    function onShowAddSampleView() {
        setShowAddSampleView(true);
    }

    function onSampleViewAdd(sample: ISampleViewModel) {
        setVm(vm.addSample(sample));
        setShowAddSampleView(false);
    }

    function onRemoveSample(sample: ISampleViewModel) {
        setVm(vm.removeSample(sample));
    }

    function onSampleViewCancel() {
        setShowAddSampleView(false);
    }

    // cascading reference data
    const [filteredCountrySubDivisions, setFilteredCountrySubDivisions] = useState<Client.GetCreatePublicCoreCuttingsRequestReferenceDataResponseCountrySubDivisionDto[]>([]);
    useEffect(() => {
        setFilteredCountrySubDivisions(state.country ? countrySubDivisions.filter((d) => d.countryId === state.country!.id) : []);
    }, [countrySubDivisions, state.country]);

    return (
        <>
            <div className="card mb-2">
                <div className="card-body">
                    <h2 className="card-title">Contact Details</h2>
                    <Forms.Group>
                        <Forms.Row>
                            <Forms.ThirdColumn>
                                <Forms.Label htmlFor="honorificField">Title</Forms.Label>
                                <KendoComboBox
                                    id="honorificField"
                                    data={honorifics}
                                    keyField="id"
                                    textField="name"
                                    filterable={false}
                                    suggest={true}
                                    value={state.honorific}
                                    onChange={(value) => updateStateAndViewModel({ ...state, honorific: value })}
                                    onBlur={updateViewModel}
                                />
                            </Forms.ThirdColumn>
                            <Forms.ThirdColumn>
                                <Forms.TextInput
                                    id="firstNameField"
                                    label="First Name"
                                    value={state.firstName}
                                    valid={validation.applyValidity("CreateCoreCuttingsRequest.FirstName")}
                                    maxLength={100}
                                    onChange={(value) => setState({ ...state, firstName: value })}
                                    onBlur={updateViewModel}
                                />
                                <ValidationMessageView name="CreateCoreCuttingsRequest.FirstName" validationModel={validation} />
                            </Forms.ThirdColumn>
                            <Forms.ThirdColumn>
                                <Forms.TextInput
                                    id="lastNameField"
                                    label="Last Name"
                                    value={state.lastName}
                                    valid={validation.applyValidity("CreateCoreCuttingsRequest.LastName")}
                                    maxLength={100}
                                    onChange={(value) => setState({ ...state, lastName: value })}
                                    onBlur={updateViewModel}
                                />
                                <ValidationMessageView name="CreateCoreCuttingsRequest.LastName" validationModel={validation} />
                            </Forms.ThirdColumn>
                        </Forms.Row>
                    </Forms.Group>
                    <Forms.Group>
                        <Forms.Row>
                            <Forms.ThirdColumn>
                                <Forms.TextInput
                                    id="companyNameField"
                                    label="Company"
                                    value={state.companyName}
                                    maxLength={100}
                                    onChange={(value) => setState({ ...state, companyName: value })}
                                    onBlur={updateViewModel}
                                />
                            </Forms.ThirdColumn>
                            <Forms.ThirdColumn>
                                <Forms.TextInput
                                    id="contactNumberField"
                                    label="Contact Number"
                                    value={state.contactNumber}
                                    valid={validation.applyValidity("CreateCoreCuttingsRequest.ContactNumber")}
                                    maxLength={100}
                                    onChange={(value) => setState({ ...state, contactNumber: value })}
                                    onBlur={updateViewModel}
                                />
                                <ValidationMessageView name="CreateCoreCuttingsRequest.ContactNumber" validationModel={validation} />
                            </Forms.ThirdColumn>
                            <Forms.ThirdColumn>
                                <Forms.TextInput
                                    id="emailAddressField"
                                    label="Email"
                                    value={state.emailAddress}
                                    valid={validation.applyValidity("CreateCoreCuttingsRequest.EmailAddress")}
                                    maxLength={100}
                                    onChange={(value) => setState({ ...state, emailAddress: value })}
                                    onBlur={updateViewModel}
                                />
                                <ValidationMessageView name="CreateCoreCuttingsRequest.EmailAddress" validationModel={validation} />
                            </Forms.ThirdColumn>
                        </Forms.Row>
                    </Forms.Group>
                    <h2 className="card-title">Address Details</h2>
                    <Forms.Group>
                        <Forms.Row>
                            <Forms.FullColumn>
                                <Forms.TextInput
                                    id="streetAddressField"
                                    label="Street Address"
                                    value={state.streetAddress}
                                    maxLength={100}
                                    onChange={(value) => setState({ ...state, streetAddress: value })}
                                    onBlur={updateViewModel}
                                />
                            </Forms.FullColumn>
                        </Forms.Row>
                    </Forms.Group>
                    <Forms.Group>
                        <Forms.Row>
                            <Forms.HalfColumn>
                                <Forms.TextInput
                                    id="suburbField"
                                    label="Town/Suburb"
                                    value={state.suburb}
                                    valid={validation.applyValidity("CreateCoreCuttingsRequest.Suburb")}
                                    maxLength={100}
                                    onChange={(value) => setState({ ...state, suburb: value })}
                                    onBlur={updateViewModel}
                                />
                                <ValidationMessageView name="CreateCoreCuttingsRequest.Suburb" validationModel={validation} />
                            </Forms.HalfColumn>
                            <Forms.HalfColumn>
                                <Forms.TextInput
                                    id="postcodeField"
                                    label="Postcode"
                                    value={state.postcode}
                                    maxLength={100}
                                    onChange={(value) => setState({ ...state, postcode: value })}
                                    onBlur={updateViewModel}
                                />
                            </Forms.HalfColumn>
                        </Forms.Row>
                    </Forms.Group>
                    <Forms.Group>
                        <Forms.Row>
                            <Forms.HalfColumn>
                                <Forms.Label htmlFor="countrySubDivisionField">State/Territory</Forms.Label>
                                <KendoDropDownList
                                    id="countrySubDivisionField"
                                    data={filteredCountrySubDivisions}
                                    keyField="id"
                                    textField="name"
                                    value={state.countrySubDivision}
                                    onChange={(value) => updateStateAndViewModel({ ...state, countrySubDivision: value })}
                                    onBlur={updateViewModel}
                                />
                            </Forms.HalfColumn>
                            <Forms.HalfColumn>
                                <Forms.Label htmlFor="countryField">Country</Forms.Label>
                                <KendoDropDownList
                                    id="countryField"
                                    data={countries}
                                    keyField="id"
                                    textField="name"
                                    value={state.country}
                                    valid={validation.applyValidity("CreateCoreCuttingsRequest.Country")}
                                    onChange={(value) => updateStateAndViewModel({ ...state, countrySubDivision: undefined, country: value })}
                                    onBlur={updateViewModel}
                                />
                                <ValidationMessageView name="CreateCoreCuttingsRequest.Country" validationModel={validation} />
                            </Forms.HalfColumn>
                        </Forms.Row>
                    </Forms.Group>
                    <h2 className="card-title">More Information</h2>
                    <Forms.Group>
                        <Forms.Row>
                            <Forms.QuarterColumn>
                                <Forms.Element label="Academia">
                                    <Forms.RadioButton
                                        name="isAcademia"
                                        id="isAcademiaYesField"
                                        label="Yes"
                                        inline
                                        valid={validation.applyValidity("CreateCoreCuttingsRequest.IsAcademia")}
                                        checked={state.isAcademia === true}
                                        onChange={() => updateStateAndViewModel({ ...state, isAcademia: true })}
                                    />
                                    <Forms.RadioButton
                                        name="isAcademia"
                                        id="isAcademiaNoField"
                                        label="No"
                                        inline
                                        valid={validation.applyValidity("CreateCoreCuttingsRequest.IsAcademia")}
                                        checked={state.isAcademia === false}
                                        onChange={() => updateStateAndViewModel({ ...state, isAcademia: false })}
                                    />
                                    <ValidationMessageView name="CreateCoreCuttingsRequest.IsAcademia" validationModel={validation} />
                                </Forms.Element>
                            </Forms.QuarterColumn>
                        </Forms.Row>
                    </Forms.Group>
                    <Forms.Group>
                        <Forms.Row>
                            <Forms.FullColumn>
                                <Forms.TextArea
                                    id="studyDescriptionield"
                                    label="Please provide a brief description of the study/analysis you intend to undertake"
                                    value={state.studyDescription}
                                    valid={validation.applyValidity("CreateCoreCuttingsRequest.StudyDescription")}
                                    maxLength={1000}
                                    onChange={(value) => setState({ ...state, studyDescription: value })}
                                    onBlur={updateViewModel}
                                />
                                <ValidationMessageView name="CreateCoreCuttingsRequest.StudyDescription" validationModel={validation} />
                            </Forms.FullColumn>
                        </Forms.Row>
                    </Forms.Group>
                    <h2 className="card-title">Required Samples</h2>
                    <div className="mt-3">
                        <button type="button" className="btn btn-primary me-2 mt-2" onClick={onShowAddSampleView}>
                            Add
                        </button>
                    </div>
                    <Forms.Group>
                        <Forms.Row>
                            <Forms.FullColumn>
                                {vm.coreCuttingsRequest.samples && vm.coreCuttingsRequest.samples.some((s) => s) && (
                                    <div className="mt-3">
                                        <Grid data={vm.coreCuttingsRequest.samples}>
                                            <GridColumn field="borehole.name" title="Borehole" width={600} />
                                            <GridColumn field="intervalTop" title="Interval (m) (Top)" width={200} />
                                            <GridColumn field="intervalBottom" title="Interval (m) (Bottom)" width={200} />
                                            <GridColumn
                                                field="sampleTypes"
                                                title="Sample Types"
                                                width={800}
                                                cell={(props) => (
                                                    <CustomGridCell gridCellProps={props}>
                                                        {props.dataItem.sampleTypes && (
                                                            <p className="form-label">
                                                                {props.dataItem.sampleTypes
                                                                    .map((st: Client.GetCreatePublicCoreCuttingsRequestReferenceDataResponseSampleTypeDto) => st.name)
                                                                    .join(", ")}
                                                            </p>
                                                        )}
                                                    </CustomGridCell>
                                                )}
                                            />
                                            <GridColumn
                                                width={100}
                                                cell={(props) => (
                                                    <CustomGridCell gridCellProps={props}>
                                                        <button type="button" className="btn btn-primary me-2 mt-2" onClick={(e) => onRemoveSample(props.dataItem)}>
                                                            Remove
                                                        </button>
                                                    </CustomGridCell>
                                                )}
                                            />
                                        </Grid>
                                    </div>
                                )}
                                <ValidationMessageView name="CreateCoreCuttingsRequest.RequiredSamples" validationModel={validation} />
                            </Forms.FullColumn>
                        </Forms.Row>
                    </Forms.Group>
                    <div className="mt-3">
                        <button className="btn btn-primary me-2" type="button" onClick={onSubmit}>
                            Submit
                        </button>
                        <button className="btn btn-primary me-2" type="button" onClick={onReset}>
                            Reset
                        </button>
                    </div>
                </div>
            </div>
            {showAddSampleView && <AddSampleController boreholes={vm.referenceData.boreholes} sampleTypes={vm.referenceData.sampleTypes} onAdd={onSampleViewAdd} onCancel={onSampleViewCancel} />}
        </>
    );
}
