import { getServicesUri } from "../../api/uriHelper";
import { logError } from "../LogHelper";
import { useRef, useEffect } from "react";

interface IDataLoaderProps {
    url: string;
    payload: any;
    onActioned: () => void;
}

export default function ODataAction(props: IDataLoaderProps): JSX.Element {
    const requestUrl = getUrl(props.url);
    const requestBody = JSON.stringify(props.payload);
    const lastSuccessfulAction = useRef<string>("");
    const runningAction = useRef<string>("");
    const isMounted = useRef<boolean>(true);

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    });

    function invokeActionIfNeeded(): void {
        // skip the action if one is running or it's the same as one that has completed
        if (runningAction.current || requestBody === lastSuccessfulAction.current) {
            return;
        }

        // initiate a fetch operation to load the data
        runningAction.current = requestBody;
        fetch(requestUrl, { method: "POST", body: requestBody })
            .then((response) => {
                if (response.ok) return;
                throw new Error();
            })
            .then(() => {
                // mark as successful and clear the running action
                lastSuccessfulAction.current = runningAction.current;
                runningAction.current = "";
                props.onActioned();

                // if we've since unmounted then abandon the operation
                if (!isMounted.current) return;

                // request a new action if it doesn't match what the user has now asked for
                if (requestBody !== lastSuccessfulAction.current) {
                    invokeActionIfNeeded();
                    return;
                }
            })
            .catch((e) => {
                // on failure, stop all actions until a change is requested
                // we can expand on this later if needed
                lastSuccessfulAction.current = runningAction.current;
                runningAction.current = "";
                logError(e); // <-- this will ensure we see the events in application insights
            });
    }

    // initiate action
    invokeActionIfNeeded();

    return <></>;

    function getUrl(url: string): string {
        return getServicesUri() + `/${url}`;
    }
}
