import React, { useEffect } from "react";
import { setLayout } from "../../shell/redux/shellActions";
import { useDispatch } from "react-redux";

interface IProps {
    title: (string | undefined)[];
    titleReactNode?: React.ReactNode | undefined;
    navbar?: React.ReactNode | undefined;
    children: React.ReactNode;
}

export default function PageLayout(props: IProps): JSX.Element {
    const dispatch = useDispatch();

    let titleDisplay = props.title.filter((t) => t !== undefined && t !== "").join(" - ");
    if (titleDisplay.length > 100) titleDisplay = titleDisplay.substring(0, 97) + "...";

    useEffect(() => {
        // titleDisplay: pass through the browser tab header
        // includePadding: true
        // showPageHeader: false - we provide a page header solution here
        dispatch(setLayout(titleDisplay, false, true));
    }, [titleDisplay]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            {props.titleReactNode ? props.titleReactNode : <h1>{titleDisplay}</h1>}
            {props.navbar}
            {props.children}
        </div>
    );
}
