// framework
import { useState, useEffect } from "react";
// kendo
import { DataResult, State } from "@progress/kendo-data-query";
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { Grid, GridColumn, GridDataStateChangeEvent } from "@progress/kendo-react-grid";
import { Filter, TextFilter, FilterChangeEvent, NumericFilter } from "@progress/kendo-react-data-tools";
// common
import ODataReader from "../../../common/odata/ODataReader";
import { useUserToken } from "../../../common/shell/shellHelper";
import { getNumericFilter, getTextFilter } from "../../../common/kendoFilter/kendoFilterOptions";
// models
import { ISeismicLineDataRow } from "./models";

interface IProps {
    selectionVersion: number;
}

export default function Controller(props: IProps) {
    const userToken = useUserToken();

    const pageSize: number = 10;
    const initialFilter: CompositeFilterDescriptor = {
        logic: "and",
        filters: [
            {
                field: "SurveyName",
                operator: "contains",
                value: "",
            },
        ],
    };

    const [gridState, setGridDataState] = useState<State>({
        sort: [
            { field: "SurveyName", dir: "asc" },
            { field: "AcquisitionName", dir: "asc" },
            { field: "LineName", dir: "asc" },
        ],
        take: pageSize,
        skip: 0,
        filter: initialFilter,
    });

    const [filter, setFilter] = useState<CompositeFilterDescriptor>(initialFilter);
    function onFilterChange(e: FilterChangeEvent): void {
        setFilter(e.filter);
        setGridDataState({ ...gridState, filter: e.filter });
    }

    const [data, setData] = useState<DataResult>({ data: [], total: 0 });
    function onDataChange(data: DataResult): void {
        setData(data);
    }

    function mapDataRow(dataRow: any): ISeismicLineDataRow {
        return dataRow;
    }

    // ensure we don't get stuck on an empty grid page
    useEffect(() => {
        if (data.data.length === 0 && data.total > 0 && gridState && gridState.skip! > 0) setGridDataState({ ...gridState, skip: 0 });
    }, [data, gridState]);

    // return
    return (
        <>
            <Filter
                value={filter}
                onChange={onFilterChange}
                fields={[
                    { name: "SurveyIdentifier", label: "Survey ID", filter: TextFilter, operators: getTextFilter(false) },
                    { name: "SurveyName", label: "Survey", filter: TextFilter, operators: getTextFilter(false) },
                    { name: "AcquisitionIdentifier", label: "Acquisition ID", filter: TextFilter, operators: getTextFilter(false) },
                    { name: "AcquisitionName", label: "Acquisition", filter: TextFilter, operators: getTextFilter(false) },
                    { name: "LineName", label: "Line Name", filter: TextFilter, operators: getTextFilter(true) },
                    { name: "FirstShotPoint", label: "First Shot Point", filter: TextFilter, operators: getTextFilter(true) },
                    { name: "LastShotPoint", label: "Last Shot Point", filter: TextFilter, operators: getTextFilter(true) },
                    { name: "LineLength", label: "Line Length (m)", filter: NumericFilter, operators: getNumericFilter(true) },
                ]}
            />
            {userToken && (
                <ODataReader
                    url={`odata/v1/public/nopims/survey/PublicNopimsSurvey/GetSeismicLinesForSelection(userToken=${userToken})`}
                    showContentAsBusyElementId="SeismicLinesSearchResultsGrid"
                    dataState={gridState}
                    onDataReceived={onDataChange}
                    mapDataRow={mapDataRow}
                    changeToken={props.selectionVersion}
                />
            )}
            <div className="mb-4">
                <Grid
                    id="SeismicLinesSearchResultsGrid"
                    sortable
                    pageable={{ pageSizeValue: pageSize, responsive: false }}
                    resizable
                    navigatable
                    {...gridState}
                    data={data}
                    onDataStateChange={(e: GridDataStateChangeEvent) => setGridDataState(e.dataState)}
                >
                    <GridColumn field="SurveyIdentifier" title="Survey ID" width={150} />
                    <GridColumn field="SurveyName" title="Survey" width={350} />
                    <GridColumn field="AcquisitionIdentifier" title="Acquisition ID" width={150} />
                    <GridColumn field="AcquisitionName" title="Acquisition" width={350} />
                    <GridColumn field="LineName" title="Line Name" width={250} />
                    <GridColumn field="FirstShotPoint" title="First Shot Point" width={150} />
                    <GridColumn field="LastShotPoint" title="Last Shot Point" width={150} />
                    <GridColumn field="LineLength" title="Line Length (m)" format={"{0:N2}"} width={180} />
                </Grid>
            </div>
        </>
    );
}
