// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../api/Client";

export interface IReferenceDataViewModel {
    honorifics: Client.GetCreatePublicFeedbackReferenceDataResponseHonorificDto[];
    feedbackTypes: Client.GetCreatePublicFeedbackReferenceDataResponseFeedbackTypeDto[];
    nopimsActivityTypes: Client.GetCreatePublicFeedbackReferenceDataResponseNopimsActivityTypeDto[];
    activities: Client.GetCreatePublicFeedbackReferenceDataResponseActivityDto[];
    dataMonitoringItemTypes: Client.GetCreatePublicFeedbackReferenceDataResponseDataMonitoringItemTypeDto[];
}

export interface IFeedbackViewModel {
    honorific?: Client.GetCreatePublicFeedbackReferenceDataResponseHonorificDto | undefined;
    customerFirstName?: string | undefined;
    customerLastName?: string | undefined;
    customerCompanyName?: string | undefined;
    customerContactNumber?: string | undefined;
    customerEmailAddress?: string | undefined;
    feedbackType?: Client.GetCreatePublicFeedbackReferenceDataResponseFeedbackTypeDto | undefined;
    nopimsActivityType?: Client.GetCreatePublicFeedbackReferenceDataResponseNopimsActivityTypeDto | undefined;
    activity?: Client.GetCreatePublicFeedbackReferenceDataResponseActivityDto | undefined;
    dataMonitoringItemType?: Client.GetCreatePublicFeedbackReferenceDataResponseDataMonitoringItemTypeDto | undefined;
    customerComments?: string | undefined;
}

export interface IRootViewModel {
    userToken: string;
    statusMessages?: Client.StatusMessagesDto;
    isBusy: boolean;
    isDirty: boolean;
    isSubmitted: boolean;
    referenceData: IReferenceDataViewModel;
    feedback: IFeedbackViewModel;

    setBusy(isBusy: boolean): IRootViewModel;
    reset(): IRootViewModel;
    refreshReferenceData(response: Client.GetCreatePublicFeedbackReferenceDataResponseDto): IRootViewModel;
    refreshFeedbackDetails(feedback: IFeedbackViewModel): IRootViewModel;
    refreshFeedbackCreateResponse(response: Client.CreatePublicFeedbackResponseDto): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor(userToken: string) {
        this.userToken = userToken;
        this.isSubmitted = false;
        this.statusMessages = undefined;
        this.isBusy = false;
        this.isDirty = false;
        this.feedback = {};
        this.referenceData = {
            honorifics: [],
            feedbackTypes: [],
            nopimsActivityTypes: [],
            activities: [],
            dataMonitoringItemTypes: [],
        };
    }

    public userToken: string;
    public isSubmitted: boolean;
    public statusMessages?: Client.StatusMessagesDto;
    public isBusy: boolean;
    public isDirty: boolean;
    public feedback: IFeedbackViewModel;
    public referenceData: IReferenceDataViewModel;

    setBusy(isBusy: boolean): IRootViewModel {
        const vm = this._clone();
        vm.isBusy = isBusy;
        return vm;
    }

    reset(): IRootViewModel {
        const vm = this._clone();
        vm.feedback = {};
        vm.isSubmitted = false;
        vm.statusMessages = undefined;
        vm.isDirty = false;
        return vm;
    }

    refreshReferenceData(response: Client.GetCreatePublicFeedbackReferenceDataResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.referenceData = {
            honorifics: response.honorifics,
            feedbackTypes: response.feedbackTypes,
            nopimsActivityTypes: response.nopimsActivityTypes,
            activities: response.activities,
            dataMonitoringItemTypes: response.dataMonitoringItemTypes,
        };
        vm.isDirty = false; // reference data load is part of the initialisation phase
        return vm;
    }

    refreshFeedbackDetails(feedback: IFeedbackViewModel): IRootViewModel {
        const vm = this._clone();
        vm.feedback.honorific = feedback.honorific;
        vm.feedback.customerFirstName = feedback.customerFirstName;
        vm.feedback.customerLastName = feedback.customerLastName;
        vm.feedback.customerCompanyName = feedback.customerCompanyName;
        vm.feedback.customerContactNumber = feedback.customerContactNumber;
        vm.feedback.customerEmailAddress = feedback.customerEmailAddress;
        vm.feedback.feedbackType = feedback.feedbackType;
        vm.feedback.nopimsActivityType = feedback.nopimsActivityType;
        vm.feedback.activity = feedback.activity;
        vm.feedback.dataMonitoringItemType = feedback.dataMonitoringItemType;
        vm.feedback.customerComments = feedback.customerComments;
        vm.isDirty = true;
        return vm;
    }

    refreshFeedbackCreateResponse(response: Client.CreatePublicFeedbackResponseDto) {
        const vm = this._clone();
        if (response.statusMessages?.isSuccess) {
            vm.isSubmitted = true;
            vm.isDirty = false;
        }
        vm.statusMessages = response.statusMessages;
        return vm;
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.feedback = clone(this.feedback);
        return vm;
    }
}
