// framework
import { clone } from "lodash";
// api
import * as Client from "../../../../api/Client";

export interface IReferenceDataViewModel {
    honorifics: Client.GetCreatePublicCoreCuttingsRequestReferenceDataResponseHonorificDto[];
    countrySubDivisions: Client.GetCreatePublicCoreCuttingsRequestReferenceDataResponseCountrySubDivisionDto[];
    countries: Client.GetCreatePublicCoreCuttingsRequestReferenceDataResponseCountryDto[];
    boreholes: Client.GetCreatePublicCoreCuttingsRequestReferenceDataResponseBoreholeDto[];
    sampleTypes: Client.GetCreatePublicCoreCuttingsRequestReferenceDataResponseSampleTypeDto[];
}

export interface ISampleViewModel {
    uniqueId: string;
    borehole?: Client.GetCreatePublicCoreCuttingsRequestReferenceDataResponseBoreholeDto | undefined;
    intervalTop?: number | undefined;
    intervalBottom?: number | undefined;
    sampleTypes?: Client.GetCreatePublicCoreCuttingsRequestReferenceDataResponseSampleTypeDto[] | undefined;
}

export interface ICoreCuttingsRequestViewModel {
    honorific?: Client.GetCreatePublicCoreCuttingsRequestReferenceDataResponseHonorificDto | undefined;
    firstName?: string | undefined;
    lastName?: string | undefined;
    companyName?: string | undefined;
    contactNumber?: string | undefined;
    emailAddress?: string | undefined;
    isAcademia?: boolean | undefined;
    studyDescription?: string | undefined;
    streetAddress?: string | undefined;
    suburb?: string | undefined;
    countrySubDivision?: Client.GetCreatePublicCoreCuttingsRequestReferenceDataResponseCountrySubDivisionDto | undefined;
    country?: Client.GetCreatePublicCoreCuttingsRequestReferenceDataResponseCountryDto | undefined;
    postcode?: string | undefined;
    samples: ISampleViewModel[];
}

export interface IRootViewModel {
    userToken: string;
    statusMessages?: Client.StatusMessagesDto;
    isBusy: boolean;
    isDirty: boolean;
    isSubmitted: boolean;
    referenceData: IReferenceDataViewModel;
    coreCuttingsRequest: ICoreCuttingsRequestViewModel;

    setBusy(isBusy: boolean): IRootViewModel;
    reset(): IRootViewModel;

    addSample(sample: ISampleViewModel): IRootViewModel;
    removeSample(sample: ISampleViewModel): IRootViewModel;

    refreshReferenceData(response: Client.GetCreatePublicCoreCuttingsRequestReferenceDataResponseDto): IRootViewModel;
    refreshCoreCuttingsRequestDetails(coreCuttingsRequest: ICoreCuttingsRequestViewModel): IRootViewModel;
    refreshCoreCuttingsRequestCreateResponse(response: Client.CreatePublicCoreCuttingsRequestResponseDto): IRootViewModel;
}

export class RootViewModel implements IRootViewModel {
    constructor(userToken: string) {
        this.userToken = userToken;
        this.isSubmitted = false;
        this.statusMessages = undefined;
        this.isBusy = false;
        this.isDirty = false;
        this.coreCuttingsRequest = {
            samples: [],
        };
        this.referenceData = {
            honorifics: [],
            countrySubDivisions: [],
            countries: [],
            boreholes: [],
            sampleTypes: [],
        };
    }

    public userToken: string;
    public isSubmitted: boolean;
    public statusMessages?: Client.StatusMessagesDto;
    public isBusy: boolean;
    public isDirty: boolean;
    public coreCuttingsRequest: ICoreCuttingsRequestViewModel;
    public referenceData: IReferenceDataViewModel;

    setBusy(isBusy: boolean): IRootViewModel {
        const vm = this._clone();
        vm.isBusy = isBusy;
        return vm;
    }

    reset(): IRootViewModel {
        const vm = this._clone();
        vm.coreCuttingsRequest = {
            samples: [],
        };
        this.setDefaultCountry(vm);
        vm.isSubmitted = false;
        vm.statusMessages = undefined;
        vm.isDirty = false;
        return vm;
    }

    addSample(sample: ISampleViewModel) {
        const vm = this._clone();
        vm.coreCuttingsRequest.samples = [...vm.coreCuttingsRequest.samples, sample];
        vm.isDirty = true;
        return vm;
    }

    removeSample(sample: ISampleViewModel) {
        const vm = this._clone();
        vm.coreCuttingsRequest.samples = vm.coreCuttingsRequest.samples.filter((s) => s.uniqueId !== sample.uniqueId);
        vm.isDirty = true;
        return vm;
    }

    refreshReferenceData(response: Client.GetCreatePublicCoreCuttingsRequestReferenceDataResponseDto): IRootViewModel {
        const vm = this._clone();
        vm.referenceData = {
            honorifics: response.honorifics,
            countrySubDivisions: response.countrySubDivisions,
            countries: response.countries,
            boreholes: response.boreholes,
            sampleTypes: response.sampleTypes,
        };
        this.setDefaultCountry(vm);
        vm.isDirty = false; // reference data load is part of the initialisation phase
        return vm;
    }

    refreshCoreCuttingsRequestDetails(coreCuttingsRequest: ICoreCuttingsRequestViewModel): IRootViewModel {
        const vm = this._clone();
        vm.coreCuttingsRequest.honorific = coreCuttingsRequest.honorific;
        vm.coreCuttingsRequest.firstName = coreCuttingsRequest.firstName;
        vm.coreCuttingsRequest.lastName = coreCuttingsRequest.lastName;
        vm.coreCuttingsRequest.companyName = coreCuttingsRequest.companyName;
        vm.coreCuttingsRequest.contactNumber = coreCuttingsRequest.contactNumber;
        vm.coreCuttingsRequest.emailAddress = coreCuttingsRequest.emailAddress;
        vm.coreCuttingsRequest.streetAddress = coreCuttingsRequest.streetAddress;
        vm.coreCuttingsRequest.suburb = coreCuttingsRequest.suburb;
        vm.coreCuttingsRequest.postcode = coreCuttingsRequest.postcode;
        vm.coreCuttingsRequest.countrySubDivision = coreCuttingsRequest.countrySubDivision;
        vm.coreCuttingsRequest.country = coreCuttingsRequest.country;
        vm.coreCuttingsRequest.isAcademia = coreCuttingsRequest.isAcademia;
        vm.coreCuttingsRequest.studyDescription = coreCuttingsRequest.studyDescription;
        vm.isDirty = true;
        return vm;
    }

    refreshCoreCuttingsRequestCreateResponse(response: Client.CreatePublicCoreCuttingsRequestResponseDto) {
        const vm = this._clone();
        if (response.statusMessages?.isSuccess) {
            vm.isSubmitted = true;
            vm.isDirty = false;
        }
        vm.statusMessages = response.statusMessages;
        return vm;
    }

    private setDefaultCountry(vm: RootViewModel) {
        vm.coreCuttingsRequest.country = vm.referenceData.countries.find((c) => c.name === "Australia");
    }

    private _clone(): RootViewModel {
        const vm = clone(this);
        vm.coreCuttingsRequest = clone(this.coreCuttingsRequest);
        return vm;
    }
}
