import { logError } from "../../../common/LogHelper";
import { createBasketClient } from "../../../api/util";
import { simpleCall } from "../../../api/callHelper";
import * as Client from "../../../api/Client";
import { IRootViewModel } from "./models/models";

export async function initialise(viewModel: IRootViewModel, setViewModel: (viewModel: IRootViewModel) => void): Promise<void> {
    let vm = viewModel;
    try {
        setViewModel((vm = vm.setBusy(true)));
        const client = createBasketClient();
        const response = await simpleCall(() => client.getSubmitBasketReferenceData(new Client.GetSubmitPublicNopimsBasketReferenceDataRequestDto()));
        setViewModel((vm = vm.refreshReferenceData(response)));
    } catch (e) {
        logError(e);
    } finally {
        setViewModel((vm = vm.setBusy(false)));
    }
}

export async function submitBasketCommand(viewModel: IRootViewModel, setViewModel: (viewModel: IRootViewModel) => void): Promise<void> {
    let vm = viewModel;
    try {
        setViewModel((vm = vm.setBusy(true)));
        const client = createBasketClient();
        const request = new Client.SubmitPublicNopimsBasketRequestDto({
            userToken: vm.userToken,
            honorificId: vm.submitDetails.customerHonorific?.id,
            firstName: vm.submitDetails.customerFirstName!,
            lastName: vm.submitDetails.customerLastName!,
            contactNumber: vm.submitDetails.customerContactNumber!,
            email: vm.submitDetails.customerEmailAddress!,
            companyName: vm.submitDetails.customerCompanyName,
            address: vm.submitDetails.customerAddress!,
            suburb: vm.submitDetails.customerSuburb!,
            postcode: vm.submitDetails.customerPostcode,
            countrySubdivisionId: vm.submitDetails.customerCountrySubdivision?.id,
            countryId: vm.submitDetails.customerCountry!.id,
            comments: vm.submitDetails.customerComments!,
        });
        const response = await simpleCall(() => client.submitBasket(request));
        setViewModel((vm = vm.refreshSubmitResponse(response)));
    } catch (e) {
        logError(e);
    } finally {
        setViewModel((vm = vm.setBusy(false)));
    }
}
