// framework
import { useState, useEffect } from "react";
import TaskLayout from "../../../common/shell/TaskLayout";
// redux
import * as Actions from "./actions";
// common
import * as toastHelper from "../../../common/toastHelper";
import { useUserToken } from "../../../common/shell/shellHelper";
import { ValidationVisibilityEnum } from "../../../common/validation/ValidationModel";
// models
import { IRootViewModel, RootViewModel } from "./models/models";
import validator from "./models/validator";
// views
import FeedbackCreateView from "./views/FeedbackCreateView";
import FeedbackCreateSubmittedView from "./views/FeedbackCreateSubmittedView";
import NopimsPageLayout from "../../shell/NopimsPageLayout";
import { nopimsLhsNavItemEnum } from "../../shell/NopimsLhsNav";

export default function Controller(): React.JSX.Element {
    const userToken = useUserToken();
    const [vm, setVm] = useState<IRootViewModel>(new RootViewModel(userToken!));

    // initial load
    useEffect(() => {
        Actions.initialise(vm, setVm);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const [validationVisibility, setValidationVisibility] = useState(ValidationVisibilityEnum.Messages);
    const validation = validator(vm, validationVisibility);

    function onReset() {
        setValidationVisibility(ValidationVisibilityEnum.Messages);
        setVm(vm.reset());
        window.scrollTo({ top: 0, behavior: "smooth" });
    }

    function onSubmit() {
        if (!validation.isValid()) {
            setValidationVisibility(ValidationVisibilityEnum.MessagesAndAdorners);
            toastHelper.showValidationErrorNotification();
            return;
        }
        setValidationVisibility(ValidationVisibilityEnum.None);
        Actions.createPublicFeedbackCommand(vm, setVm);
    }

    return (
        <NopimsPageLayout title={["Feedback Request"]} lhsNavItem={nopimsLhsNavItemEnum.Forms_Feedback}>
            <TaskLayout statusMessages={vm.statusMessages} isDirty={vm.isDirty} isBusy={vm.isBusy}>
                {vm.isSubmitted && <FeedbackCreateSubmittedView vm={vm} onReset={onReset} />}
                {!vm.isSubmitted && <FeedbackCreateView vm={vm} setVm={setVm} validation={validation} onReset={onReset} onSubmit={onSubmit} />}
            </TaskLayout>
        </NopimsPageLayout>
    );
}
