import { useSelector } from "react-redux";
import { IShellState } from "../../shell/redux/shellReducers";
import * as Client from "../../api/Client";

export function useIsDirty(): boolean {
    const layout = useSelector((state: any) => state.isDirty);
    const isDirty = layout?.isDirty ?? false;
    return isDirty;
}

export function useUserToken(): string | undefined {
    const state = useSelector((state: any) => state.shell);
    return state.userToken as string | undefined;
}

export function useFeatureSwitch(): FeatureSwitch {
    const configuration = useSelector((state: any) => state.shell) as IShellState;
    const featureSwitches = configuration?.response?.featureSwitches;

    return new FeatureSwitch(featureSwitches);
}

export class FeatureSwitch {
    constructor(featureSwitches: Array<Client.NeatsFeatureSwitchEnum> | undefined) {
        this._featureSwitches = featureSwitches;
    }

    private _featureSwitches: Array<Client.NeatsFeatureSwitchEnum> | undefined;

    public check(featureSwitch: Client.NeatsFeatureSwitchEnum): boolean {
        if (!this._featureSwitches) return false;
        return this._featureSwitches.some((fs) => fs === featureSwitch);
    }
}
