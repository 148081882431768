import * as ReactDOM from "react-dom";
import { CompositeFilterDescriptor, DataResult, FilterDescriptor, State, toODataString } from "@progress/kendo-data-query";
import { getServicesUri } from "../../api/uriHelper";
import { logError } from "../LogHelper";
import { useRef, useState, useEffect, ReactNode } from "react";
import SimpleValidationMessageView from "../validation/SimpleValidationMessageView";
import { clone } from "lodash";

interface IDataLoaderProps {
    url: string;
    dataState: State;
    onDataReceived: (data: DataResult) => void;
    mapDataRow: (dataRow: any) => any;
    showContentAsBusyElementId: string;
    changeToken?: Date | string | number | undefined;
}

export default function ODataReader(props: IDataLoaderProps): JSX.Element {
    return (
        <ODataValidator dataState={props.dataState} onDataReceived={props.onDataReceived}>
            <ODataLoaderInternal
                url={props.url}
                showContentAsBusyElementId={props.showContentAsBusyElementId}
                dataState={props.dataState}
                onDataReceived={props.onDataReceived}
                mapDataRow={props.mapDataRow}
                changeToken={props.changeToken}
            />
        </ODataValidator>
    );
}

function ODataValidator(props: { dataState: State; onDataReceived: (data: DataResult) => void; children?: ReactNode }): JSX.Element {
    const maximumFilters = 15;
    const maximumValueLength = 100;

    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (props.dataState.filter && filterExpressionContainsLongValues(props.dataState.filter!)) {
            setErrorMessage(`The filter criteria contains values that exceed ${maximumValueLength} characters; please adjust your filter criteria and try again.`);
            return;
        }
        if (props.dataState.filter && filterExpressionContainsTooManyNodes(props.dataState.filter!)) {
            setErrorMessage("Your filter criteria is too complex; please adjust your filter criteria and try again.");
            return;
        }
        setErrorMessage(undefined);
    }, [props.dataState, props.dataState.filter]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (errorMessage) props.onDataReceived({ data: [], total: 0 });
    }, [errorMessage]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {errorMessage && (
                <div className="mb-2">
                    <SimpleValidationMessageView message={errorMessage!} />
                </div>
            )}
            {!errorMessage && props.children}
        </>
    );

    function filterExpressionContainsLongValues(compositeFilterDescriptor: CompositeFilterDescriptor): boolean {
        // verifies the length of values supplied aren't too long

        // - verify filters
        const filterDescriptorResults = compositeFilterDescriptor.filters.filter((f: any) => f.value && f.value.toString().length > maximumValueLength);
        if (filterDescriptorResults.length > 0) return true;

        // - verify composite filters
        if (!compositeFilterDescriptor.filters) return false;
        const compositeFilterDescriptorResults = compositeFilterDescriptor.filters.filter((f: any) => f.filters && filterExpressionContainsLongValues(f));
        if (compositeFilterDescriptorResults.length > 0) return true;

        // - all good!
        return false;
    }

    function filterExpressionContainsTooManyNodes(compositeFilterDescriptor: CompositeFilterDescriptor): boolean {
        // verifies we don't have too many filters defined
        const numberOfFilters = getNumberOfFiltersUnderCompositeFilterDescriptor(compositeFilterDescriptor);
        return numberOfFilters > maximumFilters;
    }
}

function ODataLoaderInternal(props: IDataLoaderProps): JSX.Element {
    // the state supplies the filters, paging and sorting
    // provides de-bouncing of events, i.e.
    // - if a request is running another request will not be made until the the running request has completed
    // - when the request is completed it will check to verify if another request is needed

    const requestUrl = getUrl(props.dataState);
    const lastSuccessfulQuery = useRef<string>("");
    const runningQuery = useRef<string>("");
    const isMounted = useRef<boolean>(true);

    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    });

    function requestDataIfNeeded(): void {
        // skip the query if one is running or it's the same as one that has completed
        if (runningQuery.current || getChangeTracker(requestUrl, props.changeToken) === lastSuccessfulQuery.current) {
            return;
        }

        // initiate a fetch operation to load the data
        runningQuery.current = getChangeTracker(requestUrl, props.changeToken);
        log(`Querying: ${runningQuery.current}`);
        fetch(requestUrl, { method: "GET" })
            .then((response) => {
                if (response.ok) return response.json();
                throw new Error();
            })
            .then((json) => {
                // mark as successful and clear the running query
                lastSuccessfulQuery.current = runningQuery.current;
                runningQuery.current = "";

                // if we've since unmounted then abandon the operation
                if (!isMounted.current) return;

                // request a new query if it doesn't match what the user has now asked for
                const requestedQuery = getChangeTracker(getUrl(props.dataState), props.changeToken);
                if (requestedQuery !== lastSuccessfulQuery.current) {
                    requestDataIfNeeded();
                    return;
                }

                // apply the data
                const data: any[] = json.value;
                const total = json["@odata.count"];
                log(`Completed: ${lastSuccessfulQuery.current} (${data.length} rows for ${total} total)`);
                props.onDataReceived({
                    data: data.map((dr: any) => props.mapDataRow(dr)),
                    total: total,
                });
            })
            .catch((e) => {
                // on failure, stop all queries until a change is requested
                // the user will be presented with an empty grid
                // we can expand on this later if needed
                lastSuccessfulQuery.current = runningQuery.current;
                runningQuery.current = "";
                props.onDataReceived({ data: [], total: 0 });
                logError(e); // <-- this will ensure we see the events in application insights
            });
    }

    // initiate load
    requestDataIfNeeded();

    // apply a localised loading panel whilst a query is running
    return runningQuery.current ? <LoadingPanel elementId={props.showContentAsBusyElementId} /> : <></>;

    function LoadingPanel(props: { elementId: string }): JSX.Element {
        const loadingPanel = (
            <div className="k-loading-mask">
                <span className="k-loading-text">Loading</span>
                <div className="k-loading-image" />
                <div className="k-loading-color" />
            </div>
        );

        let element: HTMLElement | null | undefined = document.getElementById(props.elementId);

        // when attaching to a grid, attempt to find and use the content element as this appears to render faster
        element = element?.querySelector(".k-grid-content") ?? element;

        return element ? ReactDOM.createPortal(loadingPanel, element) : loadingPanel;
    }

    function getUrl(dataState: State): string {
        if (!dataState || !dataState.filter) throw new Error();

        // to avoid generating malformed requests based on incomplete query structures, we remove any empty expression groups
        // this is to work around a kendo bug; it is clear they have not performed rudimentary testing

        const dataStateClone = clone(dataState);
        dataStateClone.filter = getFilter(dataStateClone.filter!);
        let query = toODataString(dataStateClone);

        return getServicesUri() + `/${props.url}?$count=true&` + query;

        function getFilter(compositeFilterDescriptor: CompositeFilterDescriptor): CompositeFilterDescriptor {
            const result: CompositeFilterDescriptor = { logic: compositeFilterDescriptor.logic, filters: [] };

            for (let i = 0; i < compositeFilterDescriptor.filters.length; i++) {
                const f = compositeFilterDescriptor.filters[i];

                // add FilterDescriptors
                if (isFilterDescriptor(f)) result.filters.push(f);

                // add CompositeFilterDescriptors - only if they have child FilterDescriptors
                if (isCompositeFilterDescriptor(f)) {
                    const cf = f as CompositeFilterDescriptor;
                    if (getNumberOfFiltersUnderCompositeFilterDescriptor(cf) > 0) result.filters.push(getFilter(cf));
                }
            }

            return result;
        }
    }

    function getChangeTracker(requestUrl: string, changeToken: Date | string | number | undefined) {
        return requestUrl + `(${changeToken ?? ""})`;
    }
}

function getNumberOfFiltersUnderCompositeFilterDescriptor(compositeFilterDescriptor: CompositeFilterDescriptor): number {
    if (!compositeFilterDescriptor.filters || compositeFilterDescriptor.filters.length === 0) return 0;

    let result = 0;
    for (let i = 0; i < compositeFilterDescriptor.filters.length; i++) {
        const f = compositeFilterDescriptor.filters[i];

        // count FilterDescriptors
        if (isFilterDescriptor(f)) result++;

        // count FilterDescriptors under CompositeFilterDescriptors
        if (isCompositeFilterDescriptor(f)) {
            const cf = f as CompositeFilterDescriptor;
            result += getNumberOfFiltersUnderCompositeFilterDescriptor(cf);
        }
    }
    return result;
}

function isFilterDescriptor(filter: CompositeFilterDescriptor | FilterDescriptor | undefined): boolean {
    // the FilterDescriptor contains a mandatory 'Operator' field that we check for
    if (!filter) return false;
    const f: any = filter!;
    return !!f.operator;
}

function isCompositeFilterDescriptor(filter: CompositeFilterDescriptor | FilterDescriptor | undefined): boolean {
    // the CompositeFilterDescriptor contains a mandatory 'Logic' field that we check for
    if (!filter) return false;
    const f: any = filter!;
    return !!f.logic;
}

const showDebugMessages: boolean = false;

function log(message: string) {
    if (!showDebugMessages) return;
    console.debug("ODataReader: " + message);
}
