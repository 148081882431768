// framework
import React from "react";
// common
import * as Forms from "../../../../common/forms/BootstrapForms";
// models
import { IRootViewModel } from "../models/models";

interface IProps {
    vm: IRootViewModel;
    onReset: () => void;
}

export default function FeedbackCreateSubmittedView(props: IProps): React.JSX.Element {
    const { vm } = props;
    const feedback = vm.feedback;

    return (
        <>
            <div className="card mb-2">
                <div className="card-body">
                    <h2 className="card-title">Contact Details</h2>
                    <Forms.Group>
                        <Forms.Row>
                            <Forms.ThirdColumn>
                                <Forms.ReadonlyField id="honorificField" label="Title" value={feedback.honorific?.name} />
                            </Forms.ThirdColumn>
                            <Forms.ThirdColumn>
                                <Forms.ReadonlyField id="customerFirstNameField" label="First Name" value={feedback.customerFirstName} />
                            </Forms.ThirdColumn>
                            <Forms.ThirdColumn>
                                <Forms.ReadonlyField id="customerLastNameField" label="Last Name" value={feedback.customerLastName} />
                            </Forms.ThirdColumn>
                        </Forms.Row>
                    </Forms.Group>
                    <Forms.Group>
                        <Forms.Row>
                            <Forms.ThirdColumn>
                                <Forms.ReadonlyField id="customerCompanyNameField" label="Company" value={feedback.customerCompanyName} />
                            </Forms.ThirdColumn>
                            <Forms.ThirdColumn>
                                <Forms.ReadonlyField id="customerContactNumberField" label="Contact Number" value={feedback.customerContactNumber} />
                            </Forms.ThirdColumn>
                            <Forms.ThirdColumn>
                                <Forms.ReadonlyField id="customerEmailAddressField" label="Email" value={feedback.customerEmailAddress} />
                            </Forms.ThirdColumn>
                        </Forms.Row>
                    </Forms.Group>
                    <h2 className="card-title">Request Details</h2>
                    <Forms.Group>
                        <Forms.Row>
                            <Forms.ThirdColumn>
                                <Forms.ReadonlyField id="feedbackTypeField" label="Request Type" value={feedback.feedbackType?.name} />
                            </Forms.ThirdColumn>
                        </Forms.Row>
                    </Forms.Group>
                    {feedback.feedbackType?.isDataFeedbackType && (
                        <>
                            <Forms.Group>
                                <Forms.Row>
                                    <Forms.ThirdColumn>
                                        <Forms.ReadonlyField id="nopimsActivityTypeField" label="Activity Type" value={feedback.nopimsActivityType?.name} />
                                    </Forms.ThirdColumn>
                                    <Forms.ThirdColumn>
                                        <Forms.ReadonlyField id="activityField" label="Activity Name" value={feedback.activity?.name} />
                                    </Forms.ThirdColumn>
                                    <Forms.ThirdColumn>
                                        <Forms.ReadonlyField id="dataMonitoringItemTypeField" label="Data Type" value={feedback.dataMonitoringItemType?.name} />
                                    </Forms.ThirdColumn>
                                </Forms.Row>
                            </Forms.Group>
                        </>
                    )}
                    <Forms.Group>
                        <Forms.Row>
                            <Forms.FullColumn>
                                <Forms.ReadonlyTextArea id="customerCommentsField" label="Comments" value={feedback.customerComments} />
                            </Forms.FullColumn>
                        </Forms.Row>
                    </Forms.Group>
                    <div className="mt-3">
                        <button className="btn btn-primary me-2" type="button" onClick={() => props.onReset()}>
                            New
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}
