import NopimsPageLayout from "../shell/NopimsPageLayout";
import { nopimsLhsNavItemEnum } from "../shell/NopimsLhsNav";

export default function Controller(): React.JSX.Element {
    return (
        <NopimsPageLayout title={["GIS Map"]} lhsNavItem={nopimsLhsNavItemEnum.GisMap}>
            <>TODO</>
        </NopimsPageLayout>
    );
}
