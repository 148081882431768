// framework
import { useState, useEffect } from "react";
import { IoAddCircleOutline, IoCheckmarkCircle } from "react-icons/io5";
// kendo
import { Grid, GridColumn, GridDataStateChangeEvent } from "@progress/kendo-react-grid";
import { DataResult, State, CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { Filter, Operators, TextFilter, NumericFilter, DateFilter, FilterChangeEvent, BooleanFilter } from "@progress/kendo-react-data-tools";
// models
import { IWellSearchDataRow } from "./models";
// common
import ODataReader from "../../../common/odata/ODataReader";
import * as GlobalHelpers from "../../../common/GlobalHelpers";
import CustomGridCell from "../../../common/kendoGrid/CustomGridCell";
import CommentSummaryView from "../../../common/commentSummary/commentSummaryView";
import { getDateFilter, getNumericFilter, getTextFilter } from "../../../common/kendoFilter/kendoFilterOptions";

interface IProps {
    selectedItems: Array<IWellSearchDataRow>;
    onAdd: (items: Array<IWellSearchDataRow>) => void;
    onRemove: (item: IWellSearchDataRow) => void;
}

export default function Controller(props: IProps) {
    const pageSize: number = 10;
    const initialFilter: CompositeFilterDescriptor = {
        logic: "and",
        filters: [
            {
                field: "WellName",
                operator: "contains",
                value: "",
            },
        ],
    };

    const [gridState, setGridDataState] = useState<State>({
        sort: [{ field: "KickOffDate", dir: "desc" }],
        take: pageSize,
        skip: 0,
        filter: initialFilter,
    });

    const [filter, setFilter] = useState<CompositeFilterDescriptor>(initialFilter);
    const onFilterChange = (event: FilterChangeEvent) => {
        setFilter(event.filter);
        setGridDataState({ ...gridState, filter: event.filter });
    };

    const [data, setData] = useState<DataResult>({ data: [], total: 0 });
    function onDataChange(data: DataResult): void {
        setData(data);
    }

    function mapDataRow(dataRow: any): IWellSearchDataRow {
        return {
            WellId: dataRow.WellId,
            BoreholeId: dataRow.BoreholeId,
            Uwi: dataRow.Uwi,
            WellName: dataRow.WellName,
            Ubhi: dataRow.Ubhi,
            BoreholeName: dataRow.BoreholeName,
            Alias: dataRow.Alias,
            Basin: dataRow.Basin,
            SubBasin: dataRow.SubBasin,
            IsOffshore: dataRow.IsOffshore,
            Jurisdiction: dataRow.Jurisdiction,
            NopimsJurisdiction: dataRow.NopimsJurisdiction,
            NopimsLegislation: dataRow.NopimsLegislation,
            Title: dataRow.Title,
            WellOperator: dataRow.WellOperator,
            BoreholeOperator: dataRow.BoreholeOperator,
            BoreholeReason: dataRow.BoreholeReason,
            DrilledBy: dataRow.DrilledBy,
            Rig: dataRow.Rig,
            KickOffDate: dataRow.KickOffDate ? new Date(dataRow.KickOffDate) : null,
            TotalDepthDate: dataRow.TotalDepthDate ? new Date(dataRow.TotalDepthDate) : null,
            DrillersTotalDepth: dataRow.DrillersTotalDepth,
            RigReleaseDate: dataRow.RigReleaseDate ? new Date(dataRow.RigReleaseDate) : null,
            BasicRelevantDate: dataRow.BasicRelevantDate ? new Date(dataRow.BasicRelevantDate) : null,
            InterpretativeRelevantDate: dataRow.InterpretativeRelevantDate ? new Date(dataRow.InterpretativeRelevantDate) : null,
            BasicReleaseDate: dataRow.BasicReleaseDate ? new Date(dataRow.BasicReleaseDate) : null,
            InterpretativeReleaseDate: dataRow.InterpretativeReleaseDate ? new Date(dataRow.InterpretativeReleaseDate) : null,
            BoreholeStatus: dataRow.BoreholeStatus,
            Comments: dataRow.Comments,
        };
    }

    useEffect(() => {
        if (data.data.length === 0 && data.total > 0 && gridState && gridState.skip! > 0) setGridDataState({ ...gridState, skip: 0 });
    }, [data, gridState]);

    // return
    return (
        <>
            <Filter
                value={filter}
                style={{
                    width: "800px",
                }}
                onChange={onFilterChange}
                fields={[
                    { name: "Uwi", label: "Well ID", filter: TextFilter, operators: getTextFilter(false) },
                    { name: "WellName", label: "Well", filter: TextFilter, operators: getTextFilter(false) },
                    { name: "Ubhi", label: "Borehole ID", filter: TextFilter, operators: getTextFilter(false) },
                    { name: "BoreholeName", label: "Borehole", filter: TextFilter, operators: getTextFilter(false) },
                    { name: "Alias", label: "Aliases", filter: TextFilter, operators: getTextFilter(true) },
                    { name: "KickOffDate", label: "Kick Off Date", filter: DateFilter, operators: getDateFilter(true) },
                    { name: "Basin", label: "Basin", filter: TextFilter, operators: getTextFilter(true) },
                    { name: "SubBasin", label: "Sub-basin", filter: TextFilter, operators: getTextFilter(true) },
                    { name: "IsOffshore", label: "Is Offshore", filter: BooleanFilter, operators: Operators.boolean },
                    { name: "Jurisdiction", label: "State", filter: TextFilter, operators: getTextFilter(true) },
                    { name: "NopimsJurisdiction", label: "Jurisdiction", filter: TextFilter, operators: getTextFilter(true) },
                    { name: "NopimsLegislation", label: "Legislation", filter: TextFilter, operators: getTextFilter(true) },
                    { name: "Title", label: "Title", filter: TextFilter, operators: getTextFilter(true) },
                    { name: "WellOperator", label: "Well Operator", filter: TextFilter, operators: getTextFilter(true) },
                    { name: "BoreholeOperator", label: "Borehole Operator", filter: TextFilter, operators: getTextFilter(true) },
                    { name: "BoreholeReason", label: "Borehole Reason", filter: TextFilter, operators: getTextFilter(true) },
                    { name: "DrilledBy", label: "Drilled By", filter: TextFilter, operators: getTextFilter(true) },
                    { name: "Rig", label: "Rig", filter: TextFilter, operators: getTextFilter(true) },
                    { name: "TotalDepthDate", label: "TD Date", filter: DateFilter, operators: getDateFilter(true) },
                    { name: "DrillersTotalDepth", label: "Driller's TD (m)", filter: NumericFilter, operators: getNumericFilter(true) },
                    { name: "RigReleaseDate", label: "Rig Release Date", filter: DateFilter, operators: getDateFilter(true) },
                    { name: "BasicRelevantDate", label: "Basic Relevant Date", filter: DateFilter, operators: getDateFilter(true) },
                    { name: "InterpretativeRelevantDate", label: "Interp Relevant Date", filter: DateFilter, operators: getDateFilter(true) },
                    { name: "BasicReleaseDate", label: "Basic Release Date", filter: DateFilter, operators: getDateFilter(true) },
                    { name: "InterpretativeReleaseDate", label: "Interp Release Date", filter: DateFilter, operators: getDateFilter(true) },
                    { name: "BoreholeStatus", label: "Borehole Status", filter: TextFilter, operators: getTextFilter(false) },
                    { name: "Comments", label: "Comments", filter: TextFilter, operators: getTextFilter(true) },
                ]}
            />
            <ODataReader
                url="odata/v1/public/nopims/well/PublicNopimsWells"
                showContentAsBusyElementId="WellSearchResultsGrid"
                dataState={gridState}
                onDataReceived={onDataChange}
                mapDataRow={mapDataRow}
            />
            <div className="mb-4">
                <Grid
                    id="WellSearchResultsGrid"
                    sortable
                    pageable={{ pageSizeValue: pageSize, responsive: false }}
                    resizable
                    navigatable
                    {...gridState}
                    data={data}
                    onDataStateChange={(e: GridDataStateChangeEvent) => setGridDataState(e.dataState)}
                >
                    <GridColumn
                        locked={true}
                        width={60}
                        headerCell={() => (
                            <button
                                type="button"
                                className="btn btn-outline-secondary btn-sm"
                                title={`Click to add all Boreholes on this page to the Selected Boreholes.`}
                                onClick={() => props.onAdd(data.data)}
                            >
                                <IoAddCircleOutline size={20} />
                            </button>
                        )}
                        cell={(gridCellProps) => (
                            <CustomGridCell gridCellProps={gridCellProps} className={gridCellProps.className + " py-1"} style={gridCellProps.style}>
                                {!props.selectedItems.find((i) => i.BoreholeId === (gridCellProps.dataItem as IWellSearchDataRow).BoreholeId) ? (
                                    <button
                                        type="button"
                                        className="btn btn-outline-secondary btn-sm"
                                        title="Click to add this Borehole to the Selected Boreholes."
                                        onClick={() => props.onAdd([gridCellProps.dataItem])}
                                    >
                                        <IoAddCircleOutline size={20} />
                                    </button>
                                ) : (
                                    <button
                                        type="button"
                                        className="btn btn-outline-secondary btn-sm"
                                        title="Click to remove this Borehole from the Selected Boreholes."
                                        onClick={() => props.onRemove(gridCellProps.dataItem)}
                                    >
                                        <IoCheckmarkCircle size={20} color="green" />
                                    </button>
                                )}
                            </CustomGridCell>
                        )}
                    />
                    <GridColumn field="Uwi" title="Well ID" width={150} />
                    <GridColumn field="WellName" title="Well" width={250} />
                    <GridColumn field="Ubhi" title="Borehole ID" width={150} />
                    <GridColumn field="BoreholeName" title="Borehole" width={250} />
                    <GridColumn field="Alias" title="Aliases" width={250} />
                    <GridColumn field="KickOffDate" title="Kick Off Date" format={GlobalHelpers.NoptaDateFormatForKendo} width={200} />
                    <GridColumn field="Basin" title="Basin" width={250} />
                    <GridColumn field="SubBasin" title="Sub-basin" width={250} />
                    <GridColumn
                        title="Offshore"
                        width={120}
                        field="IsOffshore"
                        cell={(props) => (
                            <CustomGridCell gridCellProps={props} className="k-command-cell">
                                {props.dataItem.IsOffshore ? "Y" : "N"}
                            </CustomGridCell>
                        )}
                    />
                    <GridColumn field="Jurisdiction" title="State" width={200} />
                    <GridColumn field="NopimsJurisdiction" title="Jurisdiction" width={250} />
                    <GridColumn field="NopimsLegislation" title="Legislation" width={250} />
                    <GridColumn field="Title" title="Title" width={250} />
                    <GridColumn field="WellOperator" title="Well Operator" width={250} />
                    <GridColumn field="BoreholeOperator" title="Borehole Operator" width={250} />
                    <GridColumn field="BoreholeReason" title="Borehole Reason" width={170} />
                    <GridColumn field="DrilledBy" title="Drilled By" width={250} />
                    <GridColumn field="Rig" title="Rig" width={250} />
                    <GridColumn field="TotalDepthDate" title="TD Date" format={GlobalHelpers.NoptaDateFormatForKendo} width={200} />
                    <GridColumn field="DrillersTotalDepth" title="Driller's TD (m)" format={"{0:N}"} width={150} />
                    <GridColumn field="RigReleaseDate" title="Rig Release Date" format={GlobalHelpers.NoptaDateFormatForKendo} width={200} />
                    <GridColumn field="BasicRelevantDate" title="Basic Relevant Date" format={GlobalHelpers.NoptaDateFormatForKendo} width={200} />
                    <GridColumn field="InterpretativeRelevantDate" title="Interp Relevant Date" format={GlobalHelpers.NoptaDateFormatForKendo} width={200} />
                    <GridColumn field="BasicReleaseDate" title="Basic Release Date" format={GlobalHelpers.NoptaDateFormatForKendo} width={200} />
                    <GridColumn field="InterpretativeReleaseDate" title="Interp Release Date" format={GlobalHelpers.NoptaDateFormatForKendo} width={200} />
                    <GridColumn field="BoreholeStatus" title="Borehole Status" width={150} />
                    <GridColumn
                        title="Comments"
                        width={460}
                        field="Comments"
                        cell={(props) => (
                            <CustomGridCell gridCellProps={props} className="k-command-cell">
                                <CommentSummaryView comment={props.dataItem.Comments} maxSummaryLength={50} title="Comments"></CommentSummaryView>
                            </CustomGridCell>
                        )}
                    />
                </Grid>
            </div>
        </>
    );
}
