import { takeEvery, call, put, select } from "redux-saga/effects";
import * as Actions from "./actions";
import { createBasketClient } from "../../../api/util";
import * as Client from "../../../api/Client";
import { simpleCall } from "../../../api/callHelper";
import { INopimsBasketModel, NopimsBasketItemModel } from "./model";
import { NopimsBasketActions } from "./actions";
import { logError } from "../../../common/LogHelper";

export default function* watcher() {
    yield takeEvery(Actions.NopimsBasketActions.Initialise, initialise);
    yield takeEvery(Actions.NopimsBasketActions.ItemsAdded, update);
    yield takeEvery(Actions.NopimsBasketActions.ItemsRemoved, update);
}

function* initialise() {
    const vm: INopimsBasketModel = yield select((state: any) => state.nopimsBasket);

    // load the active basket from the service layer (there can only be 0 or 1)
    // do not show busy state as this needs to be done silently
    try {
        const response: Client.GetPublicNopimsBasketResponseDto = yield call(loadQuery, vm.userToken!);
        var items = response.items.map(
            (i) =>
                new NopimsBasketItemModel(
                    i.title,
                    i.reportId,
                    i.surveyDataId,
                    i.wellBoreholeId,
                    i.surveyAcquisitionId,
                    i.reprocessingProjectId,
                    i.itemId,
                    i.activity,
                    i.activityName,
                    i.type,
                    i.documentType
                )
        );
        yield put({ type: NopimsBasketActions.ItemsLoaded, items });
    } catch (e) {
        logError(e);
    }
}

async function loadQuery(userToken: string): Promise<Client.GetPublicNopimsBasketResponseDto> {
    const client = createBasketClient();
    const request = new Client.GetPublicNopimsBasketRequestDto({ userToken: userToken });
    const response = await simpleCall(() => client.getBasket(request));
    return response;
}

function* update() {
    const vm: INopimsBasketModel = yield select((state: any) => state.nopimsBasket);
    //  update to the service layer
    // do not show busy state as this needs to be done silently
    // load the refresh the vm after the save has occurred to help reduce concurrency issues
    // will ignore conflicts for now, just log these, will see how this goes
    try {
        yield call(updateCommand, vm);
        yield put({ type: NopimsBasketActions.Initialise });
    } catch (e) {
        logError(e);
    }
}

async function updateCommand(vm: INopimsBasketModel): Promise<Client.SavePublicNopimsBasketResponseDto> {
    const client = createBasketClient();
    const request = new Client.SavePublicNopimsBasketRequestDto({
        userToken: vm.userToken!,
        items: vm.items.map(
            (i) =>
                new Client.SavePublicNopimsBasketItemRequestDto({
                    reportId: i.reportId,
                    surveyDataId: i.surveyDataId,
                    wellBoreholeId: i.wellBoreholeId,
                    surveyAcquisitionId: i.surveyAcquisitionId,
                    reprocessingProjectId: i.reprocessingProjectId,
                })
        ),
    });
    const response = await simpleCall(() => client.saveBasket(request));
    return response;
}
