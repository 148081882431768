import { logError } from "../../../common/LogHelper";
import { createCoreCuttingsRequestClient } from "../../../api/util";
import { simpleCall } from "../../../api/callHelper";
import * as Client from "../../../api/Client";
import { IRootViewModel } from "./models/models";

export async function initialise(viewModel: IRootViewModel, setViewModel: (viewModel: IRootViewModel) => void): Promise<void> {
    let vm = viewModel;
    try {
        setViewModel((vm = vm.setBusy(true)));
        const client = createCoreCuttingsRequestClient();
        const request = new Client.GetCreatePublicCoreCuttingsRequestReferenceDataRequestDto({});
        const response = await simpleCall(() => client.getCreatePublicCoreCuttingsRequestReferenceData(request));
        setViewModel((vm = vm.refreshReferenceData(response)));
    } catch (e) {
        logError(e);
    } finally {
        setViewModel((vm = vm.setBusy(false)));
    }
}

export async function createPublicCoreCuttingsRequestCommand(viewModel: IRootViewModel, setViewModel: (viewModel: IRootViewModel) => void): Promise<void> {
    let vm = viewModel;
    try {
        setViewModel((vm = vm.setBusy(true)));
        const client = createCoreCuttingsRequestClient();
        const request = new Client.CreatePublicCoreCuttingsRequestRequestDto({
            userToken: vm.userToken,
            honorificId: vm.coreCuttingsRequest.honorific?.id,
            firstName: vm.coreCuttingsRequest.firstName!,
            lastName: vm.coreCuttingsRequest.lastName!,
            companyName: vm.coreCuttingsRequest.companyName,
            contactNumber: vm.coreCuttingsRequest.contactNumber!,
            emailAddress: vm.coreCuttingsRequest.emailAddress!,
            isAcademia: vm.coreCuttingsRequest.isAcademia!,
            studyDescription: vm.coreCuttingsRequest.studyDescription!,
            streetAddress: vm.coreCuttingsRequest.streetAddress,
            suburb: vm.coreCuttingsRequest.suburb!,
            countrySubdivisionId: vm.coreCuttingsRequest.countrySubDivision?.id,
            countryId: vm.coreCuttingsRequest.country!.id,
            postcode: vm.coreCuttingsRequest.postcode,
            boreholeSampleDetails: vm.coreCuttingsRequest.samples.map((s) => {
                return new Client.CreatePublicCoreCuttingsRequestRequestBoreholeSampleDetailDto({
                    boreholeId: s.borehole!.id,
                    intervalTop: s.intervalTop!,
                    intervalBottom: s.intervalBottom!,
                    boreholeSampleTypeIds: s.sampleTypes!.map((st) => st.id),
                });
            }),
        });
        const response = await simpleCall(() => client.createPublicCoreCuttingsRequest(request));
        setViewModel((vm = vm.refreshCoreCuttingsRequestCreateResponse(response)));
    } catch (e) {
        logError(e);
    } finally {
        setViewModel((vm = vm.setBusy(false)));
    }
}
