// framework
import * as Validation from "../../../../common/validation/ValidationModel";
// models
import * as Models from "./models";

export default function validate(vm: Models.IRootViewModel, visibility: Validation.ValidationVisibilityEnum): Validation.IValidationModel {
    const v = new Validation.ValidationModel(visibility);

    if (!vm.coreCuttingsRequest.firstName) {
        v.addError("CreateCoreCuttingsRequest.FirstName", "First Name is required.");
    }

    if (!vm.coreCuttingsRequest.lastName) {
        v.addError("CreateCoreCuttingsRequest.LastName", "Last Name is required.");
    }

    if (!vm.coreCuttingsRequest.contactNumber) {
        v.addError("CreateCoreCuttingsRequest.ContactNumber", "Phone is required.");
    }

    if (!vm.coreCuttingsRequest.emailAddress) {
        v.addError("CreateCoreCuttingsRequest.EmailAddress", "Email is required.");
    } else if (!Validation.isValidEmail(vm.coreCuttingsRequest.emailAddress!)) {
        v.addError("CreateCoreCuttingsRequest.EmailAddress", "Email is not valid.");
    }

    if (!vm.coreCuttingsRequest.suburb) {
        v.addError("CreateCoreCuttingsRequest.Suburb", "Suburb is required.");
    }

    if (!vm.coreCuttingsRequest.country) {
        v.addError("CreateCoreCuttingsRequest.Country", "Country is required.");
    }

    if (vm.coreCuttingsRequest.isAcademia === undefined) {
        v.addError("CreateCoreCuttingsRequest.IsAcademia", "Acadmeia is required.");
    }

    if (!vm.coreCuttingsRequest.studyDescription) {
        v.addError("CreateCoreCuttingsRequest.StudyDescription", "Description of the study/analysis is required.");
    }

    if (!vm.coreCuttingsRequest.samples.some((s) => s)) {
        v.addError("CreateCoreCuttingsRequest.RequiredSamples", "At least one sample detail must be provided.");
    }

    return v;
}
