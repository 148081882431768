import React from "react";
import { PanelBar, PanelBarSelectEventArguments, PanelBarUtils } from "@progress/kendo-react-layout";
import { useHistory } from "react-router-dom";
import * as RouteHelper from "../routeHelper";

export enum nopimsLhsNavItemEnum {
    Home = "Home",
    Wells = "Wells",
    Surveys = "Surveys",
    Reprocessing = "Reprocessing",
    ReleasedDocuments = "ReleasedDocuments",
    CoreLibrary = "CoreLibrary",
    GisMap = "GisMap",
    Forms = "Forms",
    Forms_Feedback = "Forms_Feedback",
    Forms_CoreCuttingsRequest = "Forms_CoreCuttingsRequest",
    ShoppingCart = "ShoppingCart",
}

interface IItem {
    id: string;
    title: string;
    children?: Array<IItem> | undefined;
}

interface IProps {
    item: nopimsLhsNavItemEnum | undefined;
}

export default function NopimsLhsNav(props: IProps) {
    const history = useHistory();

    const items: Array<IItem> = [
        { id: nopimsLhsNavItemEnum.Home, title: "NOPIMS Home" },
        { id: nopimsLhsNavItemEnum.Wells, title: "Wells" },
        { id: nopimsLhsNavItemEnum.Surveys, title: "Surveys" },
        { id: nopimsLhsNavItemEnum.Reprocessing, title: "Reprocessing" },
        { id: nopimsLhsNavItemEnum.ReleasedDocuments, title: "Released Documents" },
        { id: nopimsLhsNavItemEnum.CoreLibrary, title: "Core Library" },
        { id: nopimsLhsNavItemEnum.GisMap, title: "GIS Map" },
        {
            id: nopimsLhsNavItemEnum.Forms,
            title: "Forms",
            children: [
                { id: nopimsLhsNavItemEnum.Forms_Feedback, title: "Feedback" },
                { id: nopimsLhsNavItemEnum.Forms_CoreCuttingsRequest, title: "Cores & Cuttings" },
            ],
        },
    ];
    let itemComponents: any = PanelBarUtils.mapItemsToComponents(items as Array<any>);

    function onSelect(e: PanelBarSelectEventArguments) {
        if (!e.target.props.id) return;
        if (e.expandedItems) setExpanded(e.expandedItems);

        const route = getRoute(e.target.props.id);
        if (route) history.push(route);
    }

    const id = props.item?.toString();
    const expandedIds = id ? getExpandedItemIds(items, id) : [];
    const [expanded, setExpanded] = React.useState<string[]>(expandedIds);

    return <PanelBar children={itemComponents} selected={id} onSelect={onSelect} expanded={expanded}></PanelBar>;
}

function getExpandedItemIds(items: Array<IItem>, id: string): Array<string> {
    // and a big thanks to kendo for not supplying this solution
    let result: Array<string> = [];
    for (let i = 0; i < items.length; i++) {
        // id found in this collection: return early
        if (items[i].id === id) {
            result = [...result, id];
            break;
        }
        // id found in a child: add this node and return early
        if (items[i].children) {
            const children = getExpandedItemIds(items[i].children!, id);
            if (children.length > 0) {
                result = [...result, items[i].id, ...children];
                break;
            }
        }
    }
    return result;
}

function getRoute(id: string): string | undefined {
    // TODO need a routeHelper setup for PEPI!

    switch (id) {
        case nopimsLhsNavItemEnum.Home:
            return RouteHelper.home();
        case nopimsLhsNavItemEnum.Wells:
            return RouteHelper.wells();
        case nopimsLhsNavItemEnum.Surveys:
            return RouteHelper.surveys();
        case nopimsLhsNavItemEnum.Reprocessing:
            return RouteHelper.reprocessing();
        case nopimsLhsNavItemEnum.ReleasedDocuments:
            return RouteHelper.releasedDocuments();
        case nopimsLhsNavItemEnum.CoreLibrary:
            return RouteHelper.coreLibrary();
        case nopimsLhsNavItemEnum.GisMap:
            return RouteHelper.gisMap();
        case nopimsLhsNavItemEnum.Forms:
            return undefined;
        case nopimsLhsNavItemEnum.Forms_Feedback:
            return RouteHelper.formsFeedback();
        case nopimsLhsNavItemEnum.Forms_CoreCuttingsRequest:
            return RouteHelper.formsCoreCuttingsRequest();
        default:
            throw new Error();
    }
}
