import { shellActions, IShellActions } from "./shellActions";
import * as Client from "../../api/Client";

export interface IShellState {
    busyCount: number;
    dirtyCount: number;
    pageTitle: string | undefined;
    showPageHeader: boolean | undefined;
    includePadding: boolean | undefined;
    isStaticConfigurationLoaded: boolean | undefined;
    response?: Client.GetPublicShellFeatureSwitchesResponseDto | undefined;
    userToken?: string | undefined;
}

// initial state
const initialState: IShellState = {
    busyCount: 0,
    dirtyCount: 0,
    pageTitle: "",
    showPageHeader: true,
    includePadding: true,
    isStaticConfigurationLoaded: false,
    response: undefined,
    userToken: undefined,
};

// reducers
export function shellReducer(state: IShellState = initialState, action: IShellActions): IShellState {
    switch (action.type) {
        case shellActions.setBusyRequest:
            return { ...state, busyCount: state.busyCount + 1 };
        case shellActions.clearBusyRequest:
            return { ...state, busyCount: state.busyCount > 0 ? state.busyCount - 1 : 0 };

        case shellActions.setDirtyRequest:
            return { ...state, dirtyCount: state.dirtyCount + 1 };
        case shellActions.clearDirtyRequest:
            return { ...state, dirtyCount: state.dirtyCount > 0 ? state.dirtyCount - 1 : 0 };

        case shellActions.setLayout:
            return { ...state, pageTitle: action.pageTitle, showPageHeader: action.showPageHeader, includePadding: action.includePadding };

        case shellActions.refreshStaticConfigurationRequest:
            return { ...state, response: undefined };
        case shellActions.refreshStaticConfigurationResponse:
            return { ...state, response: action.response, isStaticConfigurationLoaded: action.isStaticConfigurationLoaded };

        case shellActions.userTokenLoaded:
            return { ...state, userToken: action.userToken! };
        default:
            return state;
    }
}
